import { applyVueInReact } from "vuereact-combined";
import React, { Component } from "react";
import axios from "@/axios";
import LoadingSpinner from "@/components/LoadingSpinner";
import Payment from "../../components/payment/Payment";

class ManakamanaCableCar extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      loading: true,
      message: "",
      contactPerson: "",
      contactMobile: "",
      contactEmail: "",
      trips: [],
      selectedTrip: {},
      passengers: [],
      amount: 0,
    };
  }
  componentDidMount() {
    axios.get("api/v2/services/manakamanacable/rate").then((res) => {
      res.data.response.Trips.map((trip) => {
        this.setState((prev) => {
          return { trips: [...prev.trips, trip] };
        });
        this.setState({ loading: false });
      });
    });
  }
  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ message: "" });
    if (name === "selectedTrip") {
      //reset the state if the selected trip is changed.
      this.setState({ passengers: [], amount: 0 });
      value = value ? JSON.parse(value) : "";
    }

    //validating number.
    if (name === "contactMobile" && !/^$|^[0-9]+$/.test(value)) {
      return;
    }

    if (name === "passenger") {
      const passengerData = JSON.parse(event.target.dataset.attribute);
      const passengerObject = {
        TripType: this.state.selectedTrip.TripType,
        PassengerType: passengerData.PassengerType,
        NoOfPassenger: Number(value),
        Price: passengerData.Price,
      };
      this.setState(
        (prev) => {
          return {
            passengers:
              Number(value) > 0
                ? prev.passengers
                    .filter(
                      (passenger) =>
                        passenger.PassengerType !== passengerData.PassengerType
                    )
                    .concat(passengerObject)
                : prev.passengers.filter(
                    (passenger) =>
                      passenger.PassengerType !== passengerData.PassengerType
                  ),
          };
        },
        () => {
          let amount = 0;
          this.state.passengers.forEach((passenger) => {
            amount += passenger.NoOfPassenger * passenger.Price;
          });
          this.setState({ amount });
        }
      );
      return;
    }
    this.setState({ [name]: value });
  };
  validationBeforePayment = () => {
    if (!Object.keys(this.state.selectedTrip).length > 0) {
      this.setState({ message: "Please select the trip." });
      return false;
    }
    if (!this.state.passengers.length > 0) {
      this.setState({
        message: "Please enter the number of passengers.",
      });
      return false;
    }
    if (!this.state.contactPerson) {
      this.setState({
        message: "Please enter the contact person.",
      });
      return false;
    }
    if (!this.state.contactEmail) {
      this.setState({
        message: "Please enter the contact email.",
      });
      return false;
    }
    if (!this.state.contactMobile) {
      this.setState({
        message: "Please enter the contact mobile.",
      });
      return false;
    }
    if (this.state.contactMobile.length < 10) {
      this.setState({
        message: "Mobile number is invalid.",
      });
      return false;
    }
    if (
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        this.state.contactEmail
      )
    ) {
      this.setState({
        message: "Email is invalid.",
      });
      return false;
    }
    return true;
  };
  previewDetail = () => {
    const passengerDetail = this.state.passengers.reduce((obj, passenger) => {
      obj["No. of" + " " + passenger.PassengerType + " " + "passenger"] =
        passenger.NoOfPassenger;
      return obj;
    }, {});

    return {
      "Customer Name": this.state.contactPerson,
      ...passengerDetail,
      "Total Amount to Pay": "Rs. " + this.state.amount,
    };
  };

  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <h5 className="card-title">Manakamana Cable car Payment.</h5>
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                <form>
                  <div className="form-group">
                    <label>Enter Contact Person</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Type Contact Person Name and press enter"
                      value={this.state.contactPerson}
                      name="contactPerson"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Enter Contact Mobile</label>
                    <input
                      className="form-control"
                      type="text"
                      pattern="[0-9]+"
                      placeholder="Type Contact Mobile press enter"
                      value={this.state.contactMobile}
                      name="contactMobile"
                      onChange={this.handleChange}
                      maxLength={10}
                    />
                  </div>
                  <div className="form-group">
                    <label>Enter Contact Email</label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Type Contact Email press enter"
                      value={this.state.contactEmail}
                      name="contactEmail"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Trip Type</label>
                    <select
                      className="custom-select"
                      name="selectedTrip"
                      onChange={this.handleChange}
                    >
                      <option value="">-- Select Trip Type --</option>
                      {this.state.trips.map((trip) => {
                        return (
                          <option
                            key={trip.TripType}
                            value={JSON.stringify(trip)}
                          >
                            {trip.TripType}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {Object.keys(this.state.selectedTrip).length > 0 && (
                    <div className="form-group fade-animation">
                      <div className="font-weight-bold">Passenger</div>
                      <div className="row m-0 mt-1 pt-2 pb-2 border align-items-center">
                        {this.state.selectedTrip.Rates.map((rate) => {
                          return (
                            <div
                              key={rate.PassengerType}
                              className="d-flex w-100"
                            >
                              <div className="col-6 col-lg-6">
                                {rate.PassengerType}
                                <p className="w-100 text-muted small">
                                  Rs. {rate.Price} per person.
                                </p>
                              </div>
                              <div className="col-6 col-lg-6">
                                <input
                                  className="form-control "
                                  type="text"
                                  name="passenger"
                                  onChange={this.handleChange}
                                  data-attribute={JSON.stringify(rate)}
                                  pattern="[0-9]+"
                                  value={
                                    this.state.passengers.filter(
                                      (passenger) => {
                                        return (
                                          passenger.PassengerType ===
                                          rate.PassengerType
                                        );
                                      }
                                    )[0]?.NoOfPassenger ?? ""
                                  }
                                  placeholder="0"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </form>
                <div className="mt-3">
                  <div className="font-weight-bold">Total Amount</div>
                  <>Rs. {this.state.amount}</>
                </div>
                <div className="fade-animation">
                  <Payment
                    paymentUri="api/v4/manakamanacable/payment"
                    validationBeforePayment={this.validationBeforePayment}
                    paymentPayload={{
                      ContactPerson: this.state.contactPerson,
                      ContactMobile: this.state.contactMobile,
                      ContactEmail: this.state.contactEmail,
                      Amount: this.state.amount,
                      Passengers: this.state.passengers,
                    }}
                    setMessage={(message) => {
                      this.setState({ message });
                    }}
                    paymentPreviewDetail={this.previewDetail()}
                    generateTicket={true}
                  />
                </div>
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}
export default ManakamanaCableCar;
